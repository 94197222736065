import React, { useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { motion } from 'framer-motion';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useForm, ValidationError } from '@formspree/react';

// add this import for the marker icon
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { Helmet } from 'react-helmet';


// Create a custom icon
const customIcon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});



const Contact: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const position: [number, number] = [28.67883, 77.1949515]; // Sir Ganga Ram Hospital coordinates
  const mapRef = useRef<HTMLDivElement>(null);


  const scrollToMap = () => {
    mapRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToForm = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const contactForm = document.getElementById('contact-form');
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth" });
    }
  }

  const [state, handleSubmit] = useForm('xjkbrezd');
  console.log(state);

  return (
    <>
      <Helmet>
        <title>Contact Dr. Ajay Aggarwal | Best Endocrinologist in Delhi</title>
        <meta name="description" content="Schedule an appointment with Dr. Ajay Aggarwal, leading endocrinologist at Sir Ganga Ram Hospital in Delhi. Expert care for diabetes, thyroid disorders, and hormonal imbalances." />
        <meta name="keywords" content="Dr. Ajay Aggarwal, endocrinologist, diabetes specialist, thyroid doctor, hormonal disorders, Delhi, Sir Ganga Ram Hospital, appointment" />
        <meta property="og:title" content="Dr. Ajay Aggarwal | Top Endocrinologist in Delhi | Sir Ganga Ram Hospital" />
        <meta property="og:description" content="Connect with Dr. Ajay Aggarwal, renowned endocrinologist at Sir Ganga Ram Hospital. Specialized care for diabetes, thyroid disorders, and hormonal imbalances in Delhi." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.sgrh.com/doctors/dr-ajay-aggarwal" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dr. Ajay Aggarwal | Leading Endocrinologist in Delhi" />
        <meta name="twitter:description" content="Book an appointment with Dr. Ajay Aggarwal at Sir Ganga Ram Hospital. Expert endocrinology care for diabetes, thyroid disorders, and hormonal imbalances in Delhi." />
      </Helmet>
      <main className='py-8 mt-6'>
        <section className="hero relative overflow-hidden bg-gradient-to-r from-blue-600 to-blue-800 text-white py-24 md:py-32">
          <motion.div className="container mx-auto px-4 relative z-10">
            <motion.div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 leading-tight">
                Your Health, Our Priority
              </h1>
              <p className="text-xl md:text-2xl mb-8 leading-relaxed">
                Connect with Dr. Ajay Aggarwal, a leading endocrinologist at Sir Ganga Ram Hospital.
                Your journey to better health begins with a simple conversation.
              </p>
              <a
                href="#contact-form"
                onClick={scrollToForm}
                className="inline-block bg-white text-gray-800 font-semibold shadow-lg py-3 px-8 text-lg transition duration-300 ease-in-out hover:bg-gray-400  hover:shadow-lg"
              >Reach Out Today</a>
            </motion.div>
          </motion.div>
          <motion.div className="absolute inset-0 z-0 opacity-80">
            <img
              src="/images/front.jpg"
              alt="Medical background"
              className="w-full h-full object-cover object-center"
            />
          </motion.div>
          <motion.div className="absolute inset-0 bg-gradient-to-t from-blue-900 via-transparent to-transparent z-0"></motion.div>
        </section>
        <section className="contact-info py-24 bg-gray-100">
          <motion.div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-16 text-gray-800">Contact Dr. Ajay</h2>
            <motion.div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              <ContactItem
                icon={<FaMapMarkerAlt className="text-blue-500" />}
                title="Address"
                content="22/2, Maithile Sharan Marg, Block 22, Shakti Nagar, Delhi, 110007"
                gradientClass="from-blue-400 to-blue-600"
                action={scrollToMap}
              />
              <ContactItem
                icon={<FaPhone className="text-green-500" />}
                title="Phone"
                content="+91-9560844391"
                gradientClass="from-green-400 to-green-600"
                action={() => window.location.href = 'tel:+919560844391'}
              />
              <ContactItem
                icon={<FaEnvelope className="text-purple-500" />}
                title="Email"
                content="info@sgrh.com"
                gradientClass="from-purple-400 to-purple-600"
                action={() => window.location.href = 'mailto:info@sgrh.com'}
              />
            </motion.div>
          </motion.div>
        </section>
        <section id="contact-form" className="contact-form bg-white py-20">
          <motion.div className="container mx-auto px-4">
            <motion.div className="max-w-6xl mx-auto">
              <motion.div className="flex flex-col lg:flex-row items-center">
                {/* Image section */}
                <motion.div className="w-full lg:w-1/2 lg:pl-8 mb-8 lg:mb-0 order-1 lg:order-2">
                  <img
                    src="/images/hero-contact.jpg"
                    alt="Doctor consultation"
                    className="rounded-lg shadow-lg w-full h-auto object-cover"
                  />
                </motion.div>
                {/* Form section */}
                <motion.div className="w-full lg:w-1/2 lg:pr-8 order-2 lg:order-1">
                  {state.succeeded ? (
                    <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 lg:mb-8 text-center lg:text-left mt-4 sm:mt-6 lg:mt-0 px-4 lg:px-0">
                      Your Form has been sent successfully!
                    </h3>
                  ) : (
                    <motion.div>
                      <h3 className="text-3xl font-bold mb-8 text-center lg:text-left mt-8 lg:mt-0">Schedule Your Appointment</h3>

                      <form onSubmit={handleSubmit} className="space-y-6">
                        <motion.div className="mb-8">
                          <label htmlFor="name" className="block text-oxford-blue text-sm font-semibold mb-2">Name </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            required
                            placeholder="Type Name"
                            className="border-b border-platinum py-2 text-sm w-full"
                          />
                        </motion.div>

                        <motion.div className="mb-8">
                          <label htmlFor="phone" className="block text-oxford-blue text-sm font-semibold mb-2">Phone</label>
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            placeholder="Type Phone Number"
                            className="border-b border-platinum py-2 text-sm w-full"
                          />
                        </motion.div>

                        <motion.div className="mb-8">
                          <label htmlFor="email" className="block text-oxford-blue text-sm font-semibold mb-2">Email Address </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            required
                            placeholder="Type Email Address"
                            className="border-b border-platinum py-2 text-sm w-full"
                          />
                          <ValidationError prefix="Email" field="email" errors={state.errors} />
                        </motion.div>
                        <motion.div className="mb-8">
                          <label htmlFor="date" className="block text-oxford-blue text-sm font-semibold mb-2">Date</label>
                          <input
                            type="date"
                            name="date"
                            id="date"
                            required
                            placeholder="Select Date"
                            className="border-b border-platinum py-2 text-sm w-full"
                          />
                          <ValidationError prefix="Date" field="date" errors={state.errors} />
                        </motion.div>

                        <motion.div className="mb-8">
                          <label htmlFor="message" className="block text-oxford-blue text-sm font-semibold mb-2">How can I help? </label>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Type Description"
                            required
                            className="border-b border-platinum py-2 text-sm w-full max-h-72 min-h-24 h-24 resize-vertical"
                          ></textarea>
                        </motion.div>

                        <button
                          type="submit"
                          className="w-full bg-blue-900 text-white font-semibold py-3 px-6 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105"
                        >
                          Schedule Appointment
                        </button>
                      </form>
                    </motion.div>)}
                </motion.div>


              </motion.div>
            </motion.div>
          </motion.div>
        </section>

        <section ref={mapRef} className="map-section py-20">
          <motion.div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">Our Location</h2>
            <motion.div className="h-96 rounded-lg overflow-hidden shadow-lg">
              <MapContainer center={position} zoom={15} style={{ height: '400px', width: '100%' }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={position} icon={customIcon}>
                  <Popup>
                    Dr. Ajay Aggarwal
                  </Popup>
                </Marker>
              </MapContainer>
            </motion.div>
          </motion.div>
        </section>

      </main>
    </>
  );
};

interface ContactCardProps {
  icon: React.ReactNode;
  title: string;
  content: string;
  gradientClass: string;
  action: () => void;
}

const ContactItem: React.FC<ContactCardProps> = ({ icon, title, content, gradientClass, action }) => (
  <motion.div
    className={`rounded-lg shadow-lg p-8 text-center hover:shadow-xl transition duration-300 bg-gradient-to-br ${gradientClass} relative overflow-hidden group cursor-pointer`}
    onClick={action}
  >
    <motion.div className="absolute inset-0 bg-white opacity-90 group-hover:opacity-95 transition-opacity duration-300"></motion.div>
    <motion.div className="relative z-10 flex flex-col items-center justify-center h-full">
      <motion.div className="text-5xl mb-6 transform group-hover:scale-110 transition-transform duration-300">
        {icon}
      </motion.div>
      <h3 className="text-2xl font-bold mb-3 text-gray-800">{title}</h3>
      <p className="text-gray-600 text-lg leading-relaxed max-w-xs mx-auto">{content}</p>
    </motion.div>
  </motion.div>

);

export default Contact;
import React, {useEffect}  from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HomeServices from '../components/Service/HomeServices';
import Testimonials from './Testimonials';
import BookAppointment from '../components/Appointment/BookAppointment';
//import Typed from 'typed.js';
import HomeBlog from '../components/Blog/HomeBlog';
import { ContentItem } from '../components/Blog/HomeBlog';

const Home: React.FC = () => {
  
  useEffect(() => {
      window.scrollTo(0,0);
      //eslint-disable-next-line
  },[])

  const featuredPost = {
    title: "Understanding and Managing Thyroid Disorders: A Comprehensive Guide",
    author: "Dr. Ajay Aggarwal",
    date: "2024-07-20",
    content: [
      {
        type: 'paragraph',
        content: "Thyroid disorders affect millions worldwide, yet they often go undiagnosed. This guide aims to shed light on thyroid function, common disorders, and effective treatment strategies."
      },
      {
        type: 'heading',
        content: "What is the Thyroid Gland?"
      },
      {
        type: 'paragraph',
        content: "The thyroid is a butterfly-shaped gland located in the neck. It produces hormones that regulate metabolism, heart rate, body temperature, and other vital functions."
      },
      {
        type: 'image',
        src: "/images/homeblog.png",
        alt: "Diagram of the thyroid gland in the human body"
      },
      {
        type: 'heading',
        content: "Common Thyroid Disorders"
      },
      {
        type: 'list',
        content: [
          "Hypothyroidism: Underactive thyroid",
          "Hyperthyroidism: Overactive thyroid",
          "Goiter: Enlarged thyroid gland",
          "Thyroid nodules: Lumps in the thyroid"
        ]
      },
      {
        type: 'heading',
        content: "Treatment Approaches"
      },
      {
        type: 'paragraph',
        content: "Treatment varies depending on the specific disorder but may include medication, radioiodine therapy, or surgery. Lifestyle changes, such as diet modifications and stress management, can also play a crucial role in managing thyroid health."
      },
      {
        type: 'paragraph',
        content: "Regular check-ups and proper diagnosis are key to effective thyroid management. If you're experiencing symptoms such as unexplained weight changes, fatigue, or mood swings, consult with an endocrinologist for a comprehensive evaluation."
      }
    ] as ContentItem[],
    tags: ["Thyroid", "Endocrinology", "Health", "Hormones"]
  };

  return (
    <>
      <Helmet>
        <title>Dr. Ajay Aggarwal - Top Endocrinologist in Delhi | Diabetes & Thyroid Specialist</title>
        <meta name="description" content="Dr. Ajay Aggarwal is a leading endocrinologist in Delhi, India. Specializing in diabetes, thyroid disorders, and hormonal imbalances. Book an appointment at Sir Ganga Ram Hospital." />
        <meta name="keywords" content="Dr. Ajay Aggarwal, endocrinologist Delhi, diabetes specialist, thyroid doctor, hormone specialist, Sir Ganga Ram Hospital, best endocrinologist India" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.drajayaggarwal.in/" />
        <meta property="og:title" content="Dr. Ajay Aggarwal - Top Endocrinologist in Delhi" />
        <meta property="og:description" content="Leading endocrinologist in Delhi specializing in diabetes, thyroid disorders, and hormonal imbalances. Consult at Sir Ganga Ram Hospital." />
        <meta property="og:image" content="https://www.drajayaggarwal.in/dr-ajay.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.drajayaggarwal.in/" />
        <meta property="twitter:title" content="Dr. Ajay Aggarwal - Top Endocrinologist in Delhi" />
        <meta property="twitter:description" content="Leading endocrinologist in Delhi specializing in diabetes, thyroid disorders, and hormonal imbalances. Consult at Sir Ganga Ram Hospital." />
        <meta property="twitter:image" content="https://www.drajayaggarwal.in/dr-ajay.jpg" />

        <link rel="canonical" href="https://www.drajayaggarwal.in/" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Dr. Ajay Aggarwal" />
      </Helmet>
      <main>
        <section className="hero bg-white relative overflow-hidden min-h-screen flex items-center mt-20">
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-blue-50 to-blue-100 rounded-br-[70%] z-0"></div>
          <div className="absolute top-0 right-0 w-full h-full bg-contain bg-no-repeat bg-right-top opacity-10 md:opacity-20 z-0" style={{ backgroundImage: "url('/images/dr-ajay-aggarwal.png')" }}></div>

          <div className="container mx-auto px-4 py-12 relative z-10">
            <div className="flex flex-col lg:flex-row items-center">
              <div className="lg:w-1/2 lg:pr-12">
                <p className="h-[20px] text-xl text-gray-600 font-semibold mb-4 tracking-wider">Dr. Ajay Aggarwal</p>
                <p className="h-[30px] text-md lg:text-md text-gray-600 font-semibold mb-4">MD, DM (Endocrinology)</p>
                <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-6 leading-tight text-gray-900">
                  Expert Endocrine Care for Your Well-being
                </h1>
                <p className="text-lg sm:text-xl mb-8 leading-relaxed text-gray-600">
                  Specialized care for diabetes, thyroid, and hormonal health
                </p>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                  <Link
                    to="/contact"
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-950 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg hover:shadow-xl"
                  >
                    Schedule Consultation
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </Link>
                  <Link
                    to="/about"
                    className="inline-flex items-center justify-center px-5 py-3 border border-blue-600 text-base font-medium rounded-md text-blue-600 bg-transparent hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out"
                  >
                    Know More
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="w-full lg:w-1/2 mt-12 lg:mt-0">
                <div className="relative max-w-sm mx-auto lg:max-w-full">
                  <div className="absolute inset-0 bg-blue-200 rounded-full opacity-20 filter blur-3xl transform rotate-12"></div>
                  <img
                    src="/images/hero-home.png"
                    alt="Dr. Ajay Aggarwal"
                    className="relative z-10 w-full h-auto rounded-lg shadow-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <HomeServices />
        <Testimonials />
        <HomeBlog post={featuredPost} />
        <BookAppointment />
      </main>
    </>
  );
};

export default Home;